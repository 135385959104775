const schoolDetails = {
    value: []
};

export const SchoolDetailsReducer = (state = schoolDetails, action) => {
    if (action.type === "GET_SCHOOL_DETAILS_REQUEST") {
        return {
            getDetailsLoading: true,
            ...state,
            is401Error: null,
        };
    }
    if (action.type === "GET_SCHOOL_DETAILS_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            ...payload,
            getDetailsLoading: false,            
            is401Error: null,
        };
    }
    if (action.type === "GET_SCHOOL_DETAILS_FAIL") {
        const { payload } = action;
        return {
            ...state,
            getDetailsLoading: false,           
            getDetailsErrorMsg: payload.errorMessage,
            is401Error: true,
        };
    }
    if (action.type === "RESET_SCHOOL_DETAILS") {
        return {
            getDetailsLoading: false,
            value: [],
            is401Error: null,
        };
    }
    if (action.type === "UPDATE_SCHOOL_DETAILS_REQUEST") {
        return {
            ...state,
            getDetailsLoading: true,           
            is401Error: null,
        };
    }
    if (action.type === "UPDATE_SCHOOL_DETAILS_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            ...payload,
            getDetailsLoading: false,           
            is401Error: null,
        };
    }
    if (action.type === "UPDATE_SCHOOL_DETAILS_FAIL") {
        const { payload } = action;
        return {
            ...state,
            getDetailsLoading: false,           
            updateDetailsErrorMsg: payload.errorMessage,
            is401Error: true,
        };
    }
    return state;
};
