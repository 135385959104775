const activeSchoolMasterList = {
    loading: false,
    value: []
};

export const ActiveSchoolMasterListReducer = (state = activeSchoolMasterList, action) => {
    if (action.type === "ACTIVE_SCHOOL_MASTER_LIST_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            value: null,
        };
    }
    if (action.type === "ACTIVE_SCHOOL_MASTER_LIST_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            ...payload,
            is401Error: null,
        };
    }
    if (action.type === "ACTIVE_SCHOOL_MASTER_LIST_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            getSchoolMasterListLoading: payload.errorMessage,
            is401Error: true,
            value: [],
        };
    }
    if (action.type === "LOGOUTUSER") {
        return {
            getSchoolMasterListLoading: '',
            loading: false,
            is401Error: false,
            value: []
        };
    }
    return state;
};
