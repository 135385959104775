
/*eslint-disable */
import { combineReducers } from 'redux';
import EcommerceReducer from './EcommerceReducer';
import NavigationReducer from './NavigationReducer';
import NotificationReducer from './NotificationReducer';
import { ActiveFormReducer } from "./ActiveFormReducer";
import { HistoryFormReducer } from "./HistoryFormReducer";
import { SchoolDetailsReducer } from "./SchoolDetailsReducer";
import { loginReducer } from "./LoginReducer";
import { formReducer } from "./FormsReducer";
import { FormStatusReducer } from "./FormStatusReducer";
import { DashboardReducer } from "./DashboardReducer";
import { ReportCardReducer } from './ReportCardReducer';
import { ActiveSchoolMasterListReducer } from './ActiveSchoolMasterListReducer';
import { AddNewSchoolReducer } from './AddNewSchoolReducer';
import { UserManagementListReducer } from './UserManagementListReducer';
import { CreateUserReducer } from './CreateUserReducer';

export const rootReducer = combineReducers({
  notifications: NotificationReducer,
  navigations: NavigationReducer,
  ecommerce: EcommerceReducer,
  activeForm: ActiveFormReducer,
  historyForm: HistoryFormReducer,
  schoolDetails: SchoolDetailsReducer,
  user: loginReducer,
  forms: formReducer,
  formStatus: FormStatusReducer,
  dashboardData: DashboardReducer,
  reportCard: ReportCardReducer,
  activeSchoolMasterList: ActiveSchoolMasterListReducer,
  newSchoolDetails: AddNewSchoolReducer,
  userManagementList: UserManagementListReducer,
  createUser: CreateUserReducer
});
