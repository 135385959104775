const formStatus = {
    value: []
};

export const FormStatusReducer = (state = formStatus, action) => {
    if (action.type === "GET_FORM_STATUS_REQUEST") {
        return {
            formStausLoading: true,
            ...state,
            is401Error: null,
            IsStatusUpdated: false,
        };
    }
    if (action.type === "GET_FORM_STATUS_RECEIVE") {
        const { payload } = action;
        return {
            formStausLoading: false,
            ...state,
            value: payload,
            is401Error: null,
            IsStatusUpdated: false,
        };
    }
    if (action.type === "GET_FORM_STATUS_REQUEST_FAIL") {
        const { payload } = action;
        return {
            formStausLoading: false,
            ...state,
            errorMessage: payload.errorMessage,
            is401Error: true,
            IsStatusUpdated: false,
        };
    }


    if (action.type === "UPDATE_FORM_STATUS_REQUEST") {
        return {
            updateFormStausLoading: true,
            ...state,
            is401Error: null,
            IsStatusUpdated: false,
        };
    }
    if (action.type === "UPDATE_FORM_STATUS_RECEIVE") {
        const { payload } = action;
        return {
            updateFormStausLoading: false,
            ...state,
            IsStatusUpdated: true,
            is401Error: null,
        };
    }
    if (action.type === "UPDATE_FORM_STATUS_FAIL") {
        const { payload } = action;
        return {
            updateFormStausLoading: false,
            ...state,
            errorMessage: payload.errorMessage,
            is401Error: true,
            IsStatusUpdated: false,
        };
    }
    return state;
};
