import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { API_URL, GET_IP_URL } from "../utils/environment.js";
import { LOGINAPI, LOGOUTAPI } from "../request/endpointConstant";

let myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");


const GETrequestOptions = {
    method: "GET",
    headers: myHeaders,
};

const POSTrequestOptions = {
    method: "POST",
    headers: myHeaders,
};

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    errorMessage: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }

    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken) => {
    //
    if (accessToken) {
        localStorage.removeItem('accessToken')
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        
        localStorage.setItem('welcomepopup', "0");
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('welcomepopup')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            // let user = action.payload.user;
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
                errorMessage: null
            }
        }
        case 'LOGIN_FAIL': {
            const { errorMessage } = action.payload
            return {
                ...state,
                isAuthenticated: false,
                errorMessage,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        let ip = "";
        try {
            ip = ""; //await axios.get(GET_IP_URL);
        } catch (ex) {
            ip = null;
        }

        try {
            const api = `${API_URL}${LOGINAPI}`;
            const response = await axios.post(api, {
                "loginId": email,
                "loginKey": password,
                "ipAddress": ip && ip.data.ip ? ip.data.ip : "",
                "sourceApp": "web"
            }).then((response) => {
                const accessToken = response.data.value.jwtToken;
                localStorage.setItem('allowedPrivilege', response.data.value.allowedPrivilege)
                localStorage.setItem('userName', response.data.value.logInUser)
                localStorage.setItem('schoolCode', response.data.value.schoolCode)
                const user = response.data.value;

                setSession(accessToken);
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                    },
                })
            }).catch((error) => {
                dispatch({
                    type: 'LOGIN_FAIL',
                    payload: {
                        errorMessage: error.errorMessage,
                    },
                })
                console.log(error);
            })

        } catch (ex) {
            dispatch({
                type: 'LOGIN',
                payload: {},
            })
        }

        // const response = await axios.post('/api/auth/login', {
        //     email,
        //     password,
        // })
        // const { accessToken, user } = response.data
        // setSession(accessToken)

        // dispatch({
        //     type: 'LOGIN',
        //     payload: {
        //         user,
        //     },
        // })
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = async (accessToken) => {
        //
        const api = `${API_URL}${LOGOUTAPI}`;
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
        const response = await axios.post(api, {});
        localStorage.clear();
        setSession(null)
        dispatch({ type: 'LOGOUT' })
        localStorage.removeItem('accessToken')
        localStorage.removeItem('allowedPrivilege');
        localStorage.removeItem('userName');
        localStorage.removeItem('schoolCode');
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                    setSession(accessToken)
                    // const response = await axios.get('/api/auth/profile')
                    // const { user } = response.data

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: { ...state.user },
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
