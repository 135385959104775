const createUser = {
    loading: false,
    value: null
};

export const CreateUserReducer = (state = createUser, action) => {
    if (action.type === "CREATE_USER_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            value: null,
            createUserError: '',
        };
    }
    if (action.type === "CREATE_USER_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            ...payload,
            is401Error: null,
            createUserError: '',
        };
    }
    if (action.type === "CREATE_USER_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            createUserError: payload.errorMessage,
            is401Error: true,
            value: null,
        };
    }
    if (action.type === "LOGOUTUSER") {
        return {
            createUserError: '',
            loading: false,
            is401Error: false,
            value: null
        };
    }
    return state;
};
