const user = {
};

export const loginReducer = (state = user, action) => {
    if (action.type === "LOGIN_REQUEST") {
        localStorage.clear();
        return {
            ...state
        };
    }
    if (action.type === "LOGIN_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
        };
    }

    if (action.type === "LOGIN_FAIL") {
        const { payload } = action;
        return {
            ...state,
            loginApiErrorMsg: payload.errorMessage,
        };
    }

    if (action.type === "LOGOUT_REQUEST") {
        return {
            ...state
        };
    }
    if (action.type === "LOGOUT_RECEIVE") {
        const { payload } = action;
        localStorage.clear();
        return {
            ...state,
        };
    }

    if (action.type === "LOGOUT_FAIL") {
        const { payload } = action;
        return {
            ...state,
            loginApiErrorMsg: payload.errorMessage,
        };
    }

    return state;
};
