const dashboardData = {
    loading: false,
    schoolClassificationValue: [],
    stateGovtSchoolValue: [],
    classWiseValue: [],
    accreditionStatusValue: [],
    stateLevelDomainScoreValue: []
};

export const DashboardReducer = (state = dashboardData, action) => {
    if (action.type === "SCHOOL_CLASS_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            schoolClassificationValue: null,
        };
    }
    if (action.type === "SCHOOL_CLASS_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            schoolClassificationValue: payload,
            is401Error: null,
        };
    }
    if (action.type === "SCHOOL_CLASS_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            loginApiErrorMsg: payload.errorMessage,
            is401Error: true,
            schoolClassificationValue: [],
        };
    }




    if (action.type === "STATE_GOVT_SCHOOL_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            stateGovtSchoolValue: null,
        };
    }
    if (action.type === "STATE_GOVT_SCHOOL_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            stateGovtSchoolValue: payload,
            is401Error: null,
        };
    }
    if (action.type === "STATE_GOVT_SCHOOL_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            loginApiErrorMsg: payload.errorMessage,
            is401Error: true,
            stateGovtSchoolValue: [],
        };
    }


    if (action.type === "CLASS_WISE_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            classWiseValue: null,
        };
    }
    if (action.type === "CLASS_WISE_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            classWiseValue: payload,
            is401Error: null,
        };
    }
    if (action.type === "CLASS_WISE_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            loginApiErrorMsg: payload.errorMessage,
            is401Error: true,
            classWiseValue: [],
        };
    }

    if (action.type === "ACCR_STATS_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            accreditionStatusValue: null,
        };
    }
    if (action.type === "ACCR_STATS_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            accreditionStatusValue: payload,
            is401Error: null,
        };
    }
    if (action.type === "ACCR_STATS_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            loginApiErrorMsg: payload.errorMessage,
            is401Error: true,
            accreditionStatusValue: [],
        };
    }

    if (action.type === "STATE_LEVEL_DOMAIN_SCORE_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            stateLevelDomainScoreValue: null,
        };
    }
    if (action.type === "STATE_LEVEL_DOMAIN_SCORE_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            stateLevelDomainScoreValue: payload,
            is401Error: null,
        };
    }
    if (action.type === "STATE_LEVEL_DOMAIN_SCORE_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            loginApiErrorMsg: payload.errorMessage,
            is401Error: true,
            stateLevelDomainScoreValue: [],
        };
    }

    if (action.type === "LOGOUTUSER") {
        return {
            loginApiErrorMsg: '',
            loading: false,
            is401Error: false,
            schoolClassificationValue: [],
            stateGovtSchoolValue: [],
            classWiseValue: [],
            accreditionStatusValue: [],
            stateLevelDomainScoreValue: []
        };
    }
    return state;
};
