import { API_URL, GET_IP_URL, ACCREDITATION_URL } from "../utils/environment.js";
import {
    LOGINAPI,
    GETACTIVEFORMAPI,
    GETHISTORYFORMAPI,
    GETSCHOOLDETAILSAPI,
    UPDATESCHOOLDETAILSAPI,
    LOGOUTAPI,
    DOMAINAPI,
    DOMAIN_QUESTION_API,
    SAVE_DOMAIN_QUESTION_API,
    GET_QUESTIONS_COMMENT_API,
    Save_QUESTIONS_COMMENT_API,
    GETFORMSTATUSAPI,
    UPDATEFORMSTATUSAPI,
    GETSCHOOLCLASSIFICATIONREPORTAPI,
    GETSTATEGOVTSCHOOLREPORTAPI,
    GETCLASSWISEENROLLMENTREPORTAPI,
    GETACCRFORMSTATSREPORTAPI,
    GETSTATELEVELDOMAINSCOREAPI, GETYEAR, GETDISTRICTS, GETBLOCKS, GETSTATUS, GETREPORTSCOREAPI,
    GETREPORTCHARTAPI,
    GETUPLOADDOCAPI,
    SUBMITSCHOOLHEADSTATUS,
    SUBMITDISTRICTQA,
    SUBMITSTATEQA,
    REJECTDISTRICTQA,
    REJECTSTATEQA,
    DOMAINPERCENTAGE, APPROVE_REJECT_ANSWER_API,
    CREATE_CYCLE_API, GET_CYCLE_API, INIT_CYCLE_API, INIT_AUDIT_CYCLE_API, CLOSE_CYCLE_API, DELETE_CYCLE_API,
    INIT_CYCLE_FORM_API,
    RAISE_COMPLAINT_API, GET_COMPLAINTLIST_API, UPDATE_COMPLAINT_STATUS_API,
    GET_COMPLAINT_COMMENT_API, SAVE_COMPLAINT_COMMENT_API, GET_COMPLAINT_UPLOAD_DOC_API,
    REGISTER_USER_API, SUBMITDEPT, REJECTDEPT, FORM_STATS_HISTORY, BULK_APPROVE_CYCLE_API,
    REJECT_REASON_API,
    FORGOT_PASSWORD_API,
    ADDNEWSCHOOLDETAILSAPI,
    GETACTIVESCHOOLMASTERLISTAPI,
    GETNEWSCHOOLDETAILSAPI, DISTRICT_APPROVE_CYCLE_API, GETDISTRICTWISESCHOOLCOUNTAPI, GETDEPTBULKAPPROVEAPI,
    GETDISTRICTPROGESSSTATUSAPI, GETDISTRICTPROGESSREPORTAPI, GETGRIEVANCEREPORTAPI,
    GETDOMAINWISESCOREREPORTAPI, GETMANAGEMENTREPORTAPI, GETDISTRICTLEVELREPORTAPI, GETDOMAINLEVELREPORTAPI, CYCLEWISEREPORTAPI, GETUSERMANAGEMENTLISTAPI, GETROLESLISTAPI, CREATEUSERAPI,
    GETROLEAPI, SAVEROLEAPI, FETCHROLEPRIVILEGEAPI, POSTROLEPRIVILEGEAPI, INACTIVEUSER, GETREVIEWCOMMENT,
    RESET_PASSWORD_API,FORGOT_USERID_API,UPDATE_USER_API,GET_USER_API,REFRESH_TOKEN_API,GET_USER_BYID_API,
    GETOVERALLREPORTCHARTAPI, GETCYCLEDATA, GETREPORTDATA, GETDOCAPI,DELETEDOCAPI
} from "./endpointConstant";
import axios from "axios";

// let myHeaders = new Headers();
// myHeaders.append("Content-Type", "application/json");

// const GETrequestOptions = {
//     method: "GET",
//     headers: myHeaders,
// };

// const POSTrequestOptions = {
//     method: "POST",
//     headers: myHeaders,
// };

export const postLogin = async (payload) => {
    // let ip = "";
    // try {
    //     ip = await axios.get(GET_IP_URL);
    // } catch (ex) {
    //     ip = null;
    // }
    // const loginPayload = {
    //     ...payload
    //     , ipAddress: ip && ip.data.ip ? ip.data.ip : "",
    // };

    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");

    const loginPayload = {
        ...payload
        , ipAddress: "",
    };
    const api = `${API_URL}${LOGINAPI}`;

    var test = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(loginPayload),
    });
    return test;
};

export const getActiveForm = async (payload) => {
    let token = localStorage.getItem('accessToken');

    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);

    const api = `${ACCREDITATION_URL}${GETACTIVEFORMAPI}`;
    return fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
};

export const getHistoryForm = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);

    const api = `${ACCREDITATION_URL}${GETHISTORYFORMAPI}`;
    return fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
};

export const getSchoolDetails = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);

    const api = `${ACCREDITATION_URL}${GETSCHOOLDETAILSAPI}`;
    return fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
};

export const UpdateSchoolDetails = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);
    const api = `${ACCREDITATION_URL}${UPDATESCHOOLDETAILSAPI}`;
    return fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
};

export const postLogout = async () => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);
    const api = `${API_URL}${LOGOUTAPI}`;
    return await fetch(api, {});
};

export const getDomain = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);

    let api = `${ACCREDITATION_URL}${DOMAINAPI}`;
    api = api.replace('{id}', payload.accrid)
    var domains = await fetch(api, {
        method: "GET",
        headers: myHeader
    });
    return domains;
};

export const getDomainQuestions = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${DOMAIN_QUESTION_API}`;
    var domains = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return domains;
};

export const saveDomainQuestions = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${SAVE_DOMAIN_QUESTION_API}`;
    var domains = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return domains;
};

export const getQuestionsCommentList = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GET_QUESTIONS_COMMENT_API}`;
    var domains = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return domains;
};

export const saveComment = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${Save_QUESTIONS_COMMENT_API}`;
    var domains = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return domains;
};

export const getFormStatus = async (accrid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETFORMSTATUSAPI}`;
    var domains = await fetch(api.replace('{0}', accrid), {
        method: "GET",
        headers: myHeader
    });
    return domains;
};

export const updateFormStatus = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${UPDATEFORMSTATUSAPI}`;
    var domains = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return domains;
};

export const getSchoolClassificationReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETSCHOOLCLASSIFICATIONREPORTAPI}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const getStateGovtSchoolReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETSTATEGOVTSCHOOLREPORTAPI}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const getClassWiseEnrollmentReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETCLASSWISEENROLLMENTREPORTAPI}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const getAccrFormStatusReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETACCRFORMSTATSREPORTAPI}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const getStateLevelDomainScoreReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETSTATELEVELDOMAINSCOREAPI}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const getYear = async () => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETYEAR}`;
    var rerurnData = await fetch(api, {
        method: "GET",
        headers: myHeader,
        //body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const getDistrict = async () => {
    let token = localStorage.getItem('accessToken');

    console.log("STRAT--v1/dashboard/filter/districts/user");
    console.log("=>" + token);
    console.log("END--v1/dashboard/filter/districts/user");


    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETDISTRICTS}`;
    var rerurnData = await fetch(api, {
        method: "GET",
        headers: myHeader,
        //body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const getBlock = async (district) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETBLOCKS}${district}`;
    var rerurnData = await fetch(api, {
        method: "GET",
        headers: myHeader,
        //body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const getStatus = async () => {
    //debugger;
    let token = localStorage.getItem('accessToken');

    console.log("STRAT--/v1/dashboard/filter/status");
    console.log("=>" + token);
    console.log("END--/v1/dashboard/filter/status");


    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETSTATUS}`;
    var rerurnData = await fetch(api, {
        method: "GET",
        headers: myHeader,
        //body: JSON.stringify(payload),
    });
    return rerurnData;
};

/**
 * @param {*} schoolAccrId 
 * @returns 
 */
export const getReportScore = async (schoolAccrId) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETREPORTSCOREAPI}/${schoolAccrId}/score`;
    var rerurnData = await fetch(api, {
        method: "GET",
        headers: myHeader
    });
    return rerurnData;
};

export const getReportChart = async (schoolAccrId) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETREPORTCHARTAPI}`;
    var rerurnData = await fetch(api.replace('{schoolAccrId}', schoolAccrId), {
        method: "GET",
        headers: myHeader
    });
    return rerurnData;
};

export const getUploadedDoc = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETUPLOADDOCAPI}`.replace('{accrid}', payload.schoolAccID).replace('{questionid}', payload.questionID);
    var domains = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return domains;
};

export const getDocument = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETDOCAPI}`.replace('{accrid}', payload.schoolAccrId).replace('{questionid}', payload.questionID).replace('{documentid}', payload.documentId);
    var domains = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return domains;
};

export const postSchoolHead = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${SUBMITSCHOOLHEADSTATUS}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const postDistrictQA = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${SUBMITDISTRICTQA}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const postStateQA = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${SUBMITSTATEQA}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};


export const rejectStateQA = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${REJECTSTATEQA}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};

export const rejectDistrictQA = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${REJECTDISTRICTQA}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};

// export const getDomainPercentage = async (payload) => {
//     let token = localStorage.getItem('accessToken');
//     let myHeader = new Headers();
//     myHeader.append("Content-Type", "application/json");
//     myHeader.append("Authorization", `${token}`);
//     let api = `${ACCREDITATION_URL}${DOMAINPERCENTAGE}`;
//     api = api.replace('{accrid}', payload.accrid);
//     api = api.replace('{domainid}', payload.domainid);
//     var rerurnData = await fetch(api, {
//         method: "GET",
//         headers: myHeader,
//     });
//     return rerurnData;
// };

export const getDomainPercentage = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${DOMAINPERCENTAGE}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload)
    });
    return rerurnData;
};

export const approveRejectAnswer = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${APPROVE_REJECT_ANSWER_API}`;
    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};

export const createCycle = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${CREATE_CYCLE_API}`;
    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};

export const getCycleList = async (cycleid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GET_CYCLE_API}`;
    api = api.replace('{cycleid}', cycleid);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};

export const initCycle = async (cycleid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${INIT_CYCLE_API}`;
    api = api.replace('{cycleid}', cycleid);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};

export const initAuditCycle = async (cycleid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${INIT_AUDIT_CYCLE_API}`;
    api = api.replace('{cycleid}', cycleid);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};

export const closeCycle = async (cycleid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${CLOSE_CYCLE_API}`;
    api = api.replace('{cycleid}', cycleid);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};

export const deleteCycle = async (cycleid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${DELETE_CYCLE_API}`;
    api = api.replace('{cycleid}', cycleid);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};

export const initCycleForm = async () => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${INIT_CYCLE_FORM_API}`;
    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};

export const saveComplaint = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${RAISE_COMPLAINT_API}`;
    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
}

export const getComplaintList = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GET_COMPLAINTLIST_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};

export const updateComplaintStatus = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${UPDATE_COMPLAINT_STATUS_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};

export const getComplaintCommentData = async (complaintId) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GET_COMPLAINT_COMMENT_API}`;
    api = api.replace('{complaintid}', complaintId);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
    });
    return returnValue;
};
export const saveComplaintComment = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${SAVE_COMPLAINT_COMMENT_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getComplaintUploadedDoc = async (complaintId) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GET_COMPLAINT_UPLOAD_DOC_API}`;
    api = api.replace('{complaintid}', complaintId);

    var returnValue = await fetch(api, {
        method: "GET",
        headers: myHeader
    });
    return returnValue;
};
export const registerUser = async (payload) => {
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    let api = `${API_URL}${REGISTER_USER_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const postDept = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${SUBMITDEPT}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};
export const rejectDept = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${REJECTDEPT}`;
    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return rerurnData;
};
export const getStatusHistory = async (accrid) => {
    let token = localStorage.getItem('accessToken');

    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${FORM_STATS_HISTORY}`;
    api = api.replace('{accrid}', accrid);

    var rerurnData = await fetch(api, {
        method: "GET",
        headers: myHeader,
    });
    return rerurnData;
};
export const bulkApprove = async (cycleid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${BULK_APPROVE_CYCLE_API}`;
    api = api.replace('{cycleid}', cycleid);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};
export const getRejectReason = async (accrid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${REJECT_REASON_API}`;

    var rerurnData = await fetch(api, {
        method: "GET",
        headers: myHeader,
    });
    return rerurnData;
};
export const forgotPassword = async (username) => {
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    let api = `${API_URL}${FORGOT_PASSWORD_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(username),
    });
    return returnValue;
};

export const AddNewSchoolDetails = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);
    const api = `${ACCREDITATION_URL}${ADDNEWSCHOOLDETAILSAPI}`;
    return fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
};

export const getActiveSchoolMasterList = async (payload) => {
    let token = localStorage.getItem('accessToken');

    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);

    const api = `${ACCREDITATION_URL}${GETACTIVESCHOOLMASTERLISTAPI}`;
    return fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
};

export const getNewSchoolDetails = async (schoolCode) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETNEWSCHOOLDETAILSAPI}`;
    var returnData = await fetch(api.replace('{schoolCode}', schoolCode), {
        method: "GET",
        headers: myHeader
    });
    return returnData;
};
export const getDistrictwiseSchoolCount = async (cycleid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETDISTRICTWISESCHOOLCOUNTAPI}`;
    api = api.replace('{cycleid}', cycleid);

    var returnValue = await fetch(api, {
        method: "GET",
        headers: myHeader
    });
    return returnValue;
};
export const districtwiseApproval = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${DISTRICT_APPROVE_CYCLE_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const departmentBulkApproval = async (cycleid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETDEPTBULKAPPROVEAPI}`;
    api = api.replace('{cycleid}', cycleid);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};
export const getDistrictProgessStatus = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETDISTRICTPROGESSSTATUSAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getDistrictProgessReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETDISTRICTPROGESSREPORTAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getGrievanceReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETGRIEVANCEREPORTAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getDomainwiseScoreReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETDOMAINWISESCOREREPORTAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getManagementReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETMANAGEMENTREPORTAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getDistrictLevelReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETDISTRICTLEVELREPORTAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getDomainLevelReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETDOMAINLEVELREPORTAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getCyclewiseReport = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${CYCLEWISEREPORTAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getUserManagementList = async (payload) => {
    let token = localStorage.getItem('accessToken');

    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);

    const api = `${API_URL}${GETUSERMANAGEMENTLISTAPI}`;
    return fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
};

export const getRolesList = async (payload) => {
    let token = localStorage.getItem('accessToken');

    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);

    const api = `${API_URL}${GETROLESLISTAPI}`;
    return fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
};

export const createUser = async (payload) => {
    let token = localStorage.getItem('accessToken');

    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `Bearer ${token}`);

    const api = `${API_URL}${CREATEUSERAPI}`;
    return fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
};

export const getRoleData = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${API_URL}${GETROLEAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const saveRole = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${API_URL}${SAVEROLEAPI}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};
export const getRolePrivilage = async (roleid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${API_URL}${FETCHROLEPRIVILEGEAPI}`;
    api = api.replace('{roleid}', roleid);

    var returnValue = await fetch(api, {
        method: "GET",
        headers: myHeader
    });
    return returnValue;
};
export const saveRolePrivilage = async (roleid, privilegeid, isselected) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${API_URL}${POSTROLEPRIVILEGEAPI}`;
    api = api.replace('{roleid}', roleid);
    api = api.replace('{privilegeid}', privilegeid);
    api = api.replace('{isselected}', isselected);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};

export const inactiveUser = async (deleteUserId) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${API_URL}${INACTIVEUSER}`;
    api = api.replace('{id}', deleteUserId);

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};

export const getViewCommets = async (aacrId) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETREVIEWCOMMENT}`;
    api = api.replace('{accrid}', aacrId);

    var returnValue = await fetch(api, {
        method: "GET",
        headers: myHeader
    });
    return returnValue;
};

export const resetPassword = async (payload) => {
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    let api = `${API_URL}${RESET_PASSWORD_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};

export const forgotLoginName = async (email) => {
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    let api = `${API_URL}${FORGOT_USERID_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(email),
    });
    return returnValue;
};

export const updateUser = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${API_URL}${UPDATE_USER_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload),
    });
    return returnValue;
};

export const getUser = async () => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${API_URL}${GET_USER_API}`;

    var returnValue = await fetch(api, {
        method: "GET",
        headers: myHeader
    });
    return returnValue;
}; 
export const refreshToken = async () => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${API_URL}${REFRESH_TOKEN_API}`;

    var returnValue = await fetch(api, {
        method: "POST",
        headers: myHeader
    });
    return returnValue;
};
export const getUserById = async (userId) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${API_URL}${GET_USER_BYID_API}`;
    api = api.replace('{userid}', userId);

    var returnValue = await fetch(api, {
        method: "GET",
        headers: myHeader
    });
    return returnValue;
}; 
export const getOverallReportChart = async (payload) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETOVERALLREPORTCHARTAPI}`;

    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload)
    });
    return rerurnData;
};
export const getCycleData = async (payload) => {
    debugger;
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${GETCYCLEDATA}`;

    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload)
    });
    return rerurnData;
};
export const getReportData = async (accrid) => {
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    let api = `${ACCREDITATION_URL}${GETREPORTDATA}`; 
    api = api.replace('{accrid}', accrid);

    var rerurnData = await fetch(api, {
        method: "GET",
        headers: myHeader
    });
    return rerurnData;
}; 

export const deleteDocument = async (payload) => {
    debugger;
    let token = localStorage.getItem('accessToken');
    let myHeader = new Headers();
    myHeader.append("Content-Type", "application/json");
    myHeader.append("Authorization", `${token}`);
    const api = `${ACCREDITATION_URL}${DELETEDOCAPI}`;

    var rerurnData = await fetch(api, {
        method: "POST",
        headers: myHeader,
        body: JSON.stringify(payload)
    });
    return rerurnData;
};