import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import { useEffect, useState } from 'react';
import LanguageContext from './contexts/LanguageContext';
import { refreshToken } from './request/requestAPI';
import { useNavigate } from 'react-router-dom';

const App = () => {
  const navigate = useNavigate();
  const content = useRoutes(routes);

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');

  useEffect(() => {
    const refreshTokenInterval = setInterval(async () => {
      if (localStorage.getItem('accessToken')) {
        // Call your refreshToken API
        console.log("==============call refresh token API")
       // console.log("accessToken=" + localStorage.getItem('accessToken'))
        const data = await refreshToken();
        let responseData = await data.json();
        console.log(JSON.stringify(responseData))
        if (data.ok) {
          console.log("success")         
          if (responseData.value) {           
            localStorage.removeItem('accessToken')
            localStorage.setItem('accessToken', responseData.value.jwtToken);
          }
        }
        else if (data.status == 401) {
          console.log("fail")     
          navigate('/session/signin', { state: '401' });;
        }
      }
    }, 900000); // 10 seconds in milliseconds

    return () => {
      // Clean up the interval when the component unmounts
      clearInterval(refreshTokenInterval);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
  }, [selectedLanguage]);

  return (
    <Provider store={Store}>
      <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
        <SettingsProvider>
          <MatxTheme>
            <AuthProvider>{content}</AuthProvider>
          </MatxTheme>
        </SettingsProvider>
      </LanguageContext.Provider>
    </Provider>
  );
};

export default App;
