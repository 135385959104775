const userManagementList = {
    loading: false,
    value: []
};

export const UserManagementListReducer = (state = userManagementList, action) => {
    if (action.type === "USER_MANAGEMENT_LIST_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            value: null,
        };
    }
    if (action.type === "USER_MANAGEMENT_LIST_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            ...payload,
            is401Error: null,
        };
    }
    if (action.type === "USER_MANAGEMENT_LIST_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            getUserManagementListLoading: payload.errorMessage,
            is401Error: true,
            value: [],
        };
    }
    if (action.type === "LOGOUTUSER") {
        return {
            getUserManagementListLoading: '',
            loading: false,
            is401Error: false,
            value: []
        };
    }
    return state;
};
