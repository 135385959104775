export const LOGINAPI = "/v1/identity/web/auth";
export const GETACTIVEFORMAPI = "/v1/form/search";
export const GETHISTORYFORMAPI = "/v1/form/history";
export const GETSCHOOLDETAILSAPI = "/v1/school/details";
export const UPDATESCHOOLDETAILSAPI = "/v1/school/update";
export const LOGOUTAPI = "/v1/identity/logout";
export const DOMAINAPI = "/v1/form/{id}/domain";
export const DOMAIN_QUESTION_API = "/v1/form/json";
export const SAVE_DOMAIN_QUESTION_API = "/v1/form/answers";
export const GET_QUESTIONS_COMMENT_API = "/v1/form/question/comment/list";
export const Save_QUESTIONS_COMMENT_API = "/v1/form/question/comment";
export const GETFORMSTATUSAPI = "/v1/form/{0}/status";
export const UPDATEFORMSTATUSAPI = "/v1/form/status/change";
export const GETSCHOOLCLASSIFICATIONREPORTAPI = "/v1/dashboard/classification/school";
export const GETSTATEGOVTSCHOOLREPORTAPI = "/v1/dashboard/classification/area";
export const GETCLASSWISEENROLLMENTREPORTAPI = "/v1/dashboard/classwise/enrollment";
export const GETACCRFORMSTATSREPORTAPI = "/v1/dashboard/chart/statuswise";
export const GETSTATELEVELDOMAINSCOREAPI = "/v1/dashboard/chart/statescore";
export const GETYEAR = "/v1/dashboard/filter/accryear";
export const GETDISTRICTS = "/v1/dashboard/filter/districts/user";
export const GETBLOCKS = "/v1/dashboard/filter/blocks?district=";
export const GETSTATUS = "/v1/dashboard/filter/status"
export const GETREPORTSCOREAPI = "/v1/report";
export const GETREPORTCHARTAPI = "/v1/report/{schoolAccrId}/chart"
export const UPLOADDOCAPI = "/v1/form/question/document"
export const GETUPLOADDOCAPI = "/v1/form/{accrid}/question/{questionid}/documents"
export const SUBMITSCHOOLHEADSTATUS = "/v1/workflow/form/sh/status/submit"
export const SUBMITDISTRICTQA = "/v1/workflow/form/dqa/status/submit"
export const REJECTDISTRICTQA = "/v1/workflow/form/dqa/status/reject"
export const SUBMITSTATEQA = "/v1/workflow/form/sqa/status/submit"
export const REJECTSTATEQA = "/v1/workflow/form/sqa/status/reject"
//export const DOMAINPERCENTAGE = "/v1/form/{accrid}/domain/{domainid}"
export const DOMAINPERCENTAGE = "/v1/form/domain/details/validate"
export const APPROVE_REJECT_ANSWER_API = "/v1/form/question/status"
export const CREATE_CYCLE_API = "/v1/cycle/create"
export const GET_CYCLE_API = "/v1/cycle/list"
export const INIT_CYCLE_API = "/v1/cycle/{cycleid}/initiate"
export const INIT_AUDIT_CYCLE_API = "/v1/cycle/{cycleid}/initiate/audit"
export const CLOSE_CYCLE_API = "/v1/cycle/{cycleid}/close"
export const DELETE_CYCLE_API = "/v1/cycle/{cycleid}/delete"
export const INIT_CYCLE_FORM_API = "/v1/form/self/init"
export const RAISE_COMPLAINT_API = "/v1/complaint/create"
export const GET_COMPLAINTLIST_API = "/v1/complaint/list"
export const UPDATE_COMPLAINT_STATUS_API = "/v1/complaint/status"
export const GET_COMPLAINT_COMMENT_API = "/v1/complaint/{complaintid}/comment/list"
export const SAVE_COMPLAINT_COMMENT_API = "/v1/complaint/comment"
export const GET_COMPLAINT_UPLOAD_DOC_API = "/v1/complaint/{complaintid}/document/list"
export const UPLOAD_COMMENT_DOCAPI = "/v1/complaint/document/upload"
export const REGISTER_USER_API = "/v1/user/register"
export const SUBMITDEPT = "/v1/workflow/form/dept/status/submit"
export const REJECTDEPT = "/v1/workflow/form/dept/status/reject"
export const FORM_STATS_HISTORY = "/v1/form/{accrid}/status/history"
export const BULK_APPROVE_CYCLE_API = "/V1/workflow/cycle/sqa/{cycleid}/submit"
export const REJECT_REASON_API = "/v1/form/reject/reason/list"
export const FORGOT_PASSWORD_API = "/v1/identity/forgot-password"
export const ADDNEWSCHOOLDETAILSAPI = "/v1/school/manage";
export const GETACTIVESCHOOLMASTERLISTAPI = "/v1/school/master/list";
export const GETNEWSCHOOLDETAILSAPI = "/v1/school/{schoolCode}";
export const GETDISTRICTWISESCHOOLCOUNTAPI = "/v1/workflow/cycle/sqa/district/{cycleid}";
export const DISTRICT_APPROVE_CYCLE_API = "/v1/workflow/cycle/sqa/district/submit";
export const GETDEPTBULKAPPROVEAPI = "/v1/workflow/cycle/dept/{cycleid}/submit";
export const GETDISTRICTPROGESSSTATUSAPI = "/v1/dashboard/progress/district/status";
export const GETDISTRICTPROGESSREPORTAPI = "/v1/dashboard/progress/district/status/heat";
export const GETGRIEVANCEREPORTAPI = "/v1/dashboard/progress/district/status/complaint";
export const GETDOMAINWISESCOREREPORTAPI = "/v1/dashboard/analysis/domain/schoolowner";
export const GETMANAGEMENTREPORTAPI = "/v1/dashboard/analysis/domain/privategovt";
export const GETDISTRICTLEVELREPORTAPI = "/v1/dashboard/analysis/district/selfvsexternal";
export const GETDOMAINLEVELREPORTAPI = "/v1/dashboard/analysis/domain/selfvsexternal";
export const CYCLEWISEREPORTAPI = "/v1/dashboard/progress/district/status/complaint";
export const GETUSERMANAGEMENTLISTAPI = "/v1/user/list";
export const GETROLESLISTAPI = "/v1/roles/list";
export const CREATEUSERAPI = "/v1/user/create"
export const GETROLEAPI = "/v1/roles/list";
export const SAVEROLEAPI = "/v1/roles";
export const FETCHROLEPRIVILEGEAPI = "/v1/roles/{roleid}/privilege";
export const POSTROLEPRIVILEGEAPI = "/v1/roles/{roleid}/{privilegeid}/{isselected}";
export const INACTIVEUSER = "/v1/user/{id}/inactive";
export const GETREVIEWCOMMENT = "/v1/form/{accrid}/comments";
export const RESET_PASSWORD_API = "/v1/identity/reset-password"
export const FORGOT_USERID_API = "/v1/identity/forgot-userid"
export const UPDATE_USER_API = "/v1/user/set"
export const GET_USER_API = "/v1/user/profile"
export const REFRESH_TOKEN_API = "/v1/identity/token/refresh"
export const GET_USER_BYID_API = "/v1/user/{userid}"
export const GETOVERALLREPORTCHARTAPI ="/v1/report/overall"
export const GETCYCLEDATA="/v1/dashboard/cycle/data"
export const GETREPORTDATA="/v1/report/{accrid}/domain/score"
export const GETDOCAPI = "/v1/form/{accrid}/question/{questionid}/documents/{documentid}"
export const DELETEDOCAPI = "/v1/form/question/document/delete"