import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const UserManagement = Loadable(lazy(() => import('./UserManagement')));
const RoleManagement = Loadable(lazy(() => import('./RoleManagement')));
const UserProfile = Loadable(lazy(() => import('./UserProfile')));

const userRoute = [{ path: '/user/userManagement', element: <UserManagement />, auth: authRoles.editor },
{ path: '/user/RoleManagement', element: <RoleManagement />, auth: authRoles.editor },
{ path: '/user/UserProfile', element: <UserProfile /> }];

export default userRoute;
