import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const DoaminList = Loadable(lazy(() => import('./Domain')));
const SchoolInfo = Loadable(lazy(() => import('./SchoolInfo')));
const AddNewSchool = Loadable(lazy(() => import('./AddNewSchool')));
const AddNewRole = Loadable(lazy(() => import('./AddNewRole')));

const formRoute = [
    { path: '/forms/domain', element: <DoaminList />, auth: authRoles.editor },
    { path: '/forms/schoolinfo', element: <SchoolInfo />, auth: authRoles.editor },
    { path: '/forms/addnewschool', element: <AddNewSchool />, auth: authRoles.editor }
];

export default formRoute;
