import useAuth from 'app/hooks/useAuth';
// import { flat } from 'app/utils/utils';
import { Navigate, useLocation } from 'react-router-dom';
// import AllPages from '../routes';

// const userHasPermission = (pathname, user, routes) => {
//   if (!user) {
//     return false;
//   }
//   const matched = routes.find((r) => r.path === pathname);

//   const authenticated =
//     matched && matched.auth && matched.auth.length ? matched.auth.includes(user.role) : true;
//   return authenticated;
// };

const AuthGuard = ({ children }) => {
  // let {
  //   isAuthenticated,
  //   // user
  // } = useAuth();

  const isAuthenticated = localStorage.getItem("accessToken");

  const { pathname } = useLocation();

  //const routes = flat(AllPages);

  //   const hasPermission = userHasPermission(pathname, user, routes);
  //   let authenticated = isAuthenticated && hasPermission;

  // // IF YOU NEED ROLE BASED AUTHENTICATION,
  // // UNCOMMENT ABOVE LINES
  // // AND COMMENT OUT BELOW authenticated VARIABLE

  let hasPermission = false;
  const allowedPrivilege = localStorage.getItem('allowedPrivilege');
  const allow = (allowedPrivilege) && allowedPrivilege.split(',')

  if (allow) {
    if (pathname == "/dashboard/default" && allow.includes("1")) { //Dashboard PAGE
      hasPermission = true;
    }
    else if (pathname == "/dashboard/accreditation" && allow.includes("7")) {  //ACCREDITATION SEACRH PAGE
      hasPermission = true;
    }
    else if (pathname == "/forms/schoolinfo" && allow.includes("8")) { //SCHOOL PROFILE VIEW
      hasPermission = true;
    }
    else if (pathname == "/forms/domain" && allow.includes("18")) { //ACCREDITATION FORM VIEW
      hasPermission = true;
    }
    else if (pathname == "/dashboard/reportcard" && allow.includes("10")) { //ACCREDITATION REPORT
      hasPermission = true;
    }
    else if (pathname == "/dashboard/cycle" && allow.includes("22")) { //CREATE CYCLE PAGE
      hasPermission = true;
    }
    else if (pathname == "/dashboard/school") { //CREATE SCHOOL PAGE
      hasPermission = true;
    }
    else if (pathname == "/forms/addnewschool") { //ADD NEW SCHOOL
      hasPermission = true;
    }
    else if (pathname == "/complaintmanagement/complaintlist" && allow.includes("23")) { //Complaint Mgnt
      hasPermission = true;
    }
    else if (pathname == "/dashboard/analysis" && allow.includes("1")) { //Dashboard Page
      hasPermission = true;
    }
    else if (pathname == "/dashboard/users" && allow.includes("35")) { //user Management
      hasPermission = true;
    }
    else if (pathname == "/user/RoleManagement" && allow.includes("36")) { //Role Management
      hasPermission = true;
    }
    else if (pathname == "/forms/addnewrole") { //ADD NEW SCHOOL
      hasPermission = true;
    }
    else if (pathname == "/dashboard/accreditationhm" && allow.includes("40")) {  //ACCREDITATION SEACRH PAGE
      hasPermission = true;
    }    
    if (pathname == "/user/UserProfile") {
      hasPermission = true;
    }
    if (pathname == "/dashboard/help") {
      hasPermission = true;
    }
    // else if (pathname == "/dashboard/users" && allow.includes("22")) { //CREATE USER PAGE
    //   hasPermission = true;
    // }
    // else if (pathname == "/dashboard/roles" && allow.includes("22")) { //CREATE ROLES PAGE
    //   hasPermission = true;
    // }
  }

  let authenticated = isAuthenticated && hasPermission;

  return (
    <>
      {authenticated ? (
        children
      ) : (
        <Navigate replace to="/session/signin" state={{ from: pathname }} />
      )}
    </>
  );
};

export default AuthGuard;
