const newSchoolDetails = {
    value: []
};

export const AddNewSchoolReducer = (state = newSchoolDetails, action) => {
    if (action.type === "ADD_NEW_SCHOOL_DETAILS_REQUEST") {
        return {
            ...state,
            getDetailsLoading: true,
            is401Error: null,
        };
    }
    if (action.type === "ADD_NEW_SCHOOL_DETAILS_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            ...payload,
            getDetailsLoading: false,
            is401Error: null,
        };
    }
    if (action.type === "ADD_NEW_SCHOOL_DETAILS_FAIL") {
        const { payload } = action;
        return {
            ...state,
            getDetailsLoading: false,
            newSchoolDetailsErrorMsg: payload.errorMessage,
            is401Error: true,
        };
    }
    if (action.type === "NEW_SCHOOL_DETAILS_REQUEST") {
        return {
            ...state,
            getDetailsLoading: true,
            is401Error: null,
        };
    }
    if (action.type === "NEW_SCHOOL_DETAILS_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            // ...payload,
            value: payload,
            getDetailsLoading: false,
            is401Error: null,
        };
    }
    if (action.type === "NEW_SCHOOL_DETAILS_FAIL") {
        const { payload } = action;
        return {
            ...state,
            getDetailsLoading: false,
            newSchoolDetailsErrorMsg: payload.errorMessage,
            is401Error: true,
        };
    }
    if (action.type === "RESET_NEW_SCHOOL_DETAILS") {
        console.log("call came for reset state ");
        return {
            ...state,
            value: [],
            getDetailsLoading: false,
            is401Error: null,
        };
    }
    return state;
};
