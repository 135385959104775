import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import hi from './hi.json';

const resources = { // list of languages
    en,
    hi,
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en', // default language to use.
        fallbackLng: 'en', // fallback language if a translation is missing
        interpolation: {
            escapeValue: false // react already safes from xss
        },
    });

export default i18n;
