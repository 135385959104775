export const formReducer = (state = [], action) => {
    if (action.type === "DOMAIN_REQUEST") {
        return {
            ...state,
            isDomainLoading: true,
            domainQuestionInfo: [],
            submitStatus: null,
            rejectStatus: null,
            domainApiErrorMsg: null
        };
    }
    if (action.type === "DOMAIN_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            domainInfo: [...payload],
            isDomainLoading: false,
            domainApiErrorMsg: null
        };
    }

    if (action.type === "DOMAIN_FAIL") {
        const { payload } = action;
        return {
            ...state,
            domainInfo: [],
            domainApiErrorMsg: payload,
            isDomainLoading: false
        };
    }


    if (action.type === "DOMAIN_QUESTINOS_REQUEST") {
        return {
            ...state,
            domainQuestionInfo: [],
            isDomainQuestionLoading: true,
            domainQuestionApiErrorMsg: null
        };
    }
    if (action.type === "DOMAIN_QUESTINOS_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            domainQuestionInfo: [...payload],
            isDomainQuestionLoading: false,
            domainQuestionApiErrorMsg: null
        };
    }

    if (action.type === "DOMAIN_QUESTINOS_FAIL") {
        const { payload } = action;
        return {
            ...state,
            domainQuestionApiErrorMsg: payload,
            isDomainQuestionLoading: false

        };
    }

    if (action.type === "SAVE_QUESTINOS_REQUEST") {
        return {
            ...state,
            isSaveQuestionLoading: true
        };
    }
    if (action.type === "SAVE_QUESTINOS_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            savedQuestionData: payload,
            isSaveQuestionLoading: false
        };
    }

    if (action.type === "SAVE_QUESTINOS_FAIL") {
        const { payload } = action;
        return {
            ...state,
            saveQuestionApiErrorMsg: payload,
            isSaveQuestionLoading: false

        };
    }

    if (action.type === "SHOW_SAVE_QUESTINOS_MESSAGE") {
        const { payload } = action;
        return {
            ...state,
            savedQuestionResponse: payload,
            isShowSuccessMessage: true

        };
    }

    if (action.type === "HIDE_SAVE_QUESTINOS_MESSAGE") {
        return {
            ...state,
            savedQuestionResponse: null,
            isShowSuccessMessage: false

        };
    }

    if (action.type === "SHOW_TAB_SWITCH_ERROR") {
        return {
            ...state,
            isShowTabError: true

        };
    }

    if (action.type === "HIDE_TAB_SWITCH_ERROR") {
        return {
            ...state,
            isShowTabError: false

        };
    }

    if (action.type === "GET_QUESTINOS_COMMENT_REQUEST") {
        return {
            ...state,
            isGetQuestionsCommentList: true
        };
    }
    if (action.type === "GET_QUESTINOS_COMMENT_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            questionsCommentList: payload,
            isGetQuestionsCommentList: false
        };
    }

    if (action.type === "SGET_QUESTINOS_COMMENT_FAIL") {
        const { payload } = action;
        return {
            ...state,
            questionsCommentListErrorMsg: payload,
            isGetQuestionsCommentList: false

        };
    }

    if (action.type === "SAVE_QUESTINOS_COMMENT_REQUEST") {
        return {
            ...state,
            isSaveCommentLoading: true,
            isShowCommentErrorMessage: false
        };
    }
    if (action.type === "SAVE_QUESTINOS_COMMENT_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            saveComment: payload,
            isSaveCommentLoading: false,
            isShowCommentErrorMessage: false
        };
    }

    if (action.type === "Save_QUESTINOS_COMMENT_FAIL") {
        const { payload } = action;
        return {
            ...state,
            saveComment: payload,
            isSaveCommentLoading: false,
            isShowCommentErrorMessage: true

        };
    }

    if (action.type === "SHOW_COMMENT_SAVE_MESSAGE") {
        return {
            ...state,
            isShowCommentSaveMessage: true

        };
    }

    if (action.type === "HIDE_COMMENT_SAVE_MESSAGE") {
        return {
            ...state,
            isShowCommentSaveMessage: false

        };
    }



    if (action.type === "HIDE_COMMENT_ERROR_MESSAGE") {
        return {
            ...state,
            isShowCommentErrorMessage: false

        };
    }

    if (action.type === "GET_UPLOAD_DOC_REQUEST") {
        return {
            ...state,
            isGetUploadDocLoading: true
        };
    }
    if (action.type === "GET_UPLOAD_DOC_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            uploadedDoc: payload,
            isGetUploadDocLoading: false

        };
    }

    if (action.type === "GET_UPLOAD_DOC_FAIL") {
        const { payload } = action;
        return {
            ...state,
            uploadedDoc: payload,
            isGetUploadDocLoading: false
        };
    }

    if (action.type === "REQUEST_SUBMIT_WORKFLOW_STATUS") {
        return {
            ...state,
            isSubmitRequestLoading: true,
            isSubmitRequestError: false,
            submitStatus: null,
        };
    }
    if (action.type === "RECEIVE_SUBMIT_WORKFLOW_STATUS") {
        const { payload } = action;
        return {
            ...state,
            submitStatus: payload,
            isSubmitRequestLoading: false,
            isSubmitRequestError: false

        };
    }

    if (action.type === "FAIL_SUBMIT_WORKFLOW_STATUS") {
        const { payload } = action;
        return {
            ...state,
            submitStatus: null,
            isSubmitRequestLoading: false,
            isSubmitRequestError: true
        };
    }


    if (action.type === "REQUEST_REJECT_WORKFLOW_STATUS") {
        return {
            ...state,
            isRejectRequestLoading: true,
            isRejectRequestError: false
        };
    }
    if (action.type === "RECEIVE_REJECT_WORKFLOW_STATUS") {
        const { payload } = action;
        return {
            ...state,
            rejectStatus: payload,
            isRejectRequestLoading: false,
            isRejectRequestError: false

        };
    }

    if (action.type === "FAIL_REJECT_WORKFLOW_STATUS") {
        const { payload } = action;
        return {
            ...state,
            rejectStatus: payload,
            isRejectRequestLoading: false,
            isRejectRequestError: true
        };
    }

    if (action.type === "APPROVE_REJECT_QUESTINOS_REQUEST") {
        return {
            ...state,
            isApproveRejectLoading: true
        };
    }
    if (action.type === "APPROVE_REJECT_QUESTINOS_RECEIVE") {
        const { payload } = action;
        return {
            ...state,
            approveRejectValue: payload,
            isApproveRejectLoading: false,
        };
    }

    if (action.type === "APPROVE_REJECT_QUESTINOS_FAIL") {
        const { payload } = action;
        return {
            ...state,
            approveRejectValue: payload,
            isApproveRejectLoading: false

        };
    }
    if (action.type === "QUESTIONSTATUS_UPDATE") {
        return {
            ...state,
           updatedQuestionAnswers : action.payload

        };
    }
    if (action.type === "LOGOUT") {
        alert("StateClear reducer");
        return {
            ...state,
            domainApiErrorMsg: null
        };
    }
    // if (action.type === "GET_DOC_REQUEST") {
    //     return {
    //         ...state,
    //         isDocLoading: true
    //     };
    // }
    // if (action.type === "GET_DOC_RECEIVE") {
    //     const { payload } = action;
    //     return {
    //         ...state,
    //         uploadedDocData: payload,
    //         isDocLoading: false

    //     };
    // }

    // if (action.type === "GET_DOC_FAIL") {
    //     const { payload } = action;
    //     return {
    //         ...state,
    //         uploadedDocData: payload,
    //         isDocLoading: false
    //     };
    // }
    return state;
};
