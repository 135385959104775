const initialState = {
    loading: false,
    reportDataValue: [],
    reportChartData: []
};

export const ReportCardReducer = (state = initialState, action) => {
    if (action.type === "REPORT_SCORE_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            reportDataValue: null,
        };
    }
    if (action.type === "REPORT_SCORE_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            reportDataValue: [...payload],
            is401Error: null,
        };
    }
    if (action.type === "REPORT_SCORE_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            loginApiErrorMsg: payload.errorMessage,
            is401Error: true,
            reportDataValue: [],
        };
    }
    if (action.type === "REPORT_CHART_REQUEST") {
        return {
            loading: true,
            ...state,
            is401Error: null,
            reportDataValue: null,
        };
    }
    if (action.type === "REPORT_CHART_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            reportChartData: [...payload],
            is401Error: null,
        };
    }
    if (action.type === "REPORT_CHART_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            loginApiErrorMsg: payload.errorMessage,
            is401Error: true,
            reportChartData: [],
        };
    }
    if (action.type === "LOGOUTUSER") {
        return {
            loginApiErrorMsg: '',
            loading: false,
            is401Error: false,
            reportChartData: []
        };
    }
    return state;
};
