import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Dashboard = Loadable(lazy(() => import('./Dashboard')));
const Analytics = Loadable(lazy(() => import('./Analytics')));
const ReportCard = Loadable(lazy(() => import('./ReportCard')));
const Cycle = Loadable(lazy(() => import('./Cycle')));
const School = Loadable(lazy(() => import('./School')));
const Complaint = Loadable(lazy(() => import('../ComplaintManagement/ComplaintList')));
const DashboardInfo = Loadable(lazy(() => import('./DashboardInfo')));
const Users = Loadable(lazy(() => import('./Users')));
const Roles = Loadable(lazy(() => import('../user/RoleManagement')));
const AnalyticsHM = Loadable(lazy(() => import('./AnalyticsHM')));
const Help = Loadable(lazy(() => import('./Help')));

const dashboardRoutes = [
  { path: '/dashboard/default', element: <Dashboard />, auth: authRoles.admin },
  { path: '/dashboard/accreditation', element: <Analytics />, auth: authRoles.admin },
  { path: '/dashboard/ReportCard', element: <ReportCard />, auth: authRoles.admin },
  { path: '/dashboard/cycle', element: <Cycle />, auth: authRoles.admin },
  { path: '/complaintmanagement/complaintlist', element: <Complaint />, auth: authRoles.admin },
  { path: '/dashboard/school', element: <School />, auth: authRoles.admin },
  { path: '/dashboard/analysis', element: <DashboardInfo />, auth: authRoles.admin },
  { path: '/dashboard/users', element: <Users />, auth: authRoles.admin },
  { path: '/dashboard/roles', element: <Roles />, auth: authRoles.admin },
  { path: '/dashboard/accreditationhm', element: <AnalyticsHM />, auth: authRoles.admin },
  { path: '/dashboard/help', element: <Help />, auth: authRoles.admin },
];

export default dashboardRoutes;
