const historyForm = {
    loading: false,
    value: []
};

export const HistoryFormReducer = (state = historyForm, action) => {
    if (action.type === "HISTORY_FORM_REQUEST") {
        return {
            loading: true,
            ...state
        };
    }
    if (action.type === "HISTORY_FORM_RECEIVE") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            ...payload
        };
    }
    if (action.type === "HISTORY_FORM_FAIL") {
        const { payload } = action;
        return {
            loading: false,
            ...state,
            loginApiErrorMsg: payload.errorMessage,
        };
    }
    return state;
};
